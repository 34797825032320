import React from "react";
import TrackVisibility from "react-on-screen";
import Pagetitle from "../elements/Pagetitle";
import Stat from "../elements/Stat";
import Slider from "react-slick";

const images = [
  {
    id: 1,
    src: "images/slider-photo-1.png",
    alt: "Notangles",
  },
  {
    id: 2,
    src: "images/slider-photo-6.png",
    alt: "First Year Camp",
  },
  {
    id: 3,
    src: "images/slider-photo-3.png",
    alt: "Projects",
  },
  {
    id: 4,
    src: "images/slider-photo-4.png",
    alt: "Pubcrawl",
  },
  {
    id: 5,
    src: "images/slider-photo-5.png",
    alt: "O-week",
  },
  {
    id: 6,
    src: "images/slider-photo-2.png",
    alt: "Socials",
  },
];

const aboutContent = {
  name: "Angella",
  avatarImage: "/images/angella-avatar.png",
  content: [
    "Hello \u{1F44B}\ufe0f,",
    "I'm Angella, a 3rd-year Computer Science student at UNSW. I harbour a strong passion for helping people, whether it'd be through creating useful web apps or participating in various volunteering opportunities. It makes me happy being able put a smile on someone's face!",
    "In my spare time, I like to hang out with friends and finding new restaurants to go to. I also recently discovered a newfound passion in film photography (and I won't shut up about it). As you can probably tell, I'm all about making long-lasting memories with the people I love most."
  ]
};

const progressData = [
  {
    id: 1,
    title: "Extraverted",
    percantage: 71,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Intuitive",
    percantage: 68,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Feeling",
    percantage: 74,
    progressColor: "#6C6CE5",
  },
  {
    id: 4,
    title: "Prospecting",
    percantage: 58,
    progressColor: "#64a35c",
  },
];

function About() {

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me &#128059;&#xfe0f;" />
        <Slider {...sliderSettings} className="testimonials-wrapper">
          {images.map((images) => (
            <div key={images.id}>
              <div className="col-md">
                <div className="timeline exp bg-white rounded overflow-hidden" >
                  <img src={images.src} alt={images.alt} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <br />
        <div className="row">
          <div className="col-md">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  {aboutContent.content.map((content, index) => (
                    <p key={index}>{content}</p>
                  ))}
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Stat progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
