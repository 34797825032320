import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const cseInvolvements = [
  {
    id: 1,
    title: "Casual Academic",
    years: "2022 - ongoing",
    content:
      "Teaches COMP1511 - Programming Fundamentals and indoctrinate students into the cult of CSE.",
  },
  {
    id: 2,
    title: "CSESoc Vice President of Development",
    years: "2022 - ongoing",
    content:
      "Manages and overlooks the Flagship Projects, Culture, Content, UI/UX, Training Program and Education teams. Come up with new initiatives to prosper the dev community at UNSW.",
  },
  {
    id: 3,
    title: "CSESoc Projects Subcommittee",
    years: "2022",
    content:
      "Works on (and potentially breaks) Notangles, helps trainees on their projects, carries out crazy internal Projects events, works with Education on Open Dev workshops and banter like crazy in chat.",
  },
  {
    id: 4,
    title: "CSESoc Socials Subcommittee",
    years: "2022",
    content:
      "Helps organise and run social events for CSESoc: babysits crying campees, flips sausages every Wednesday afternoon and tests my limits at bars.",
  },
  {
    id: 5,
    title: "CompClub Summer Workshop Mentor",
    years: "2022",
    content:
      "Teaches high school students on basic Python, Web Dev, Security and other fun topics, although I'd rather be teaching C (not biased).",
  },
  {
    id: 6,
    title: "CSESoc Camp Leader",
    years: "2022",
    content:
      "Helps organise and run CSESoc Camp, cleans up anything and everything, telling crying campees everything will be OK and not getting enough sleep.",
  },
  {
    id: 7,
    title: "CSESoc Peer Mentor",
    years: "2022",
    content:
      "Leads a group of first-year CSE students to help them find the best coffees on campus and instil Andrew Taylor's dating advices into them.",
  }
];

const unswInvolvements = [
  {
    id: 1,
    title: "Wellness Warriors",
    years: "2021",
    content:
      "Helps organise and run activations to promote the mental wellbeing of UNSW students, such as the flagship Stress Less Week.",
  },
  {
    id: 2,
    title: "Phil'",
    years: "2021",
    content:
      "Collects donations and runs charity events, such as the 24Hr FUN-A-THON to raise money for the Sydney Children's Hospital.",
  },
  {
    id: 3,
    title: "One Step Walking Group",
    years: "2021",
    content:
      "Helps organise and run weekly walking groups to promote the physical wellbeing of UNSW students.",
  },
];

const otherInvolvements = [
  {
    id: 1,
    title: "Beyond Blue",
    years: "2021",
    content:
      "Encourages conversations about mental health in the community by representing Beyond Blue and collecting donations at events such as conferences, expos, community festivals and sporting events.",
  },
  {
    id: 2,
    title: "OzHarvest",
    years: "2021",
    content:
      "Assists OzHarvest's Waterloo Free Market in providing free food hampers and nutritional support to the vulnerable community.",
  },
];

function Experiences() {

  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experiences &#127759;&#xfe0f;" />
        <div className="row">
          <div className="col-md-6">
            <h2>CSE Involvements</h2>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {cseInvolvements.map((education) => (
                  <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <h2>UNSW Involvements</h2>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {unswInvolvements.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <h2>Other Involvements</h2>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {otherInvolvements.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
